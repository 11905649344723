import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

interface AuthContextProps {
    isAuthenticated: boolean;
    login: (token: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const isTokenExpired = (token: string): boolean => {
        try {
            const { exp } = jwtDecode<{ exp: number }>(token);
            return Date.now() / 1000 >= exp; // `exp` is in seconds, convert current time to seconds
        } catch (error) {
            console.error('Invalid token:', error);
            return true;
        }
    };

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
        const token = localStorage.getItem('adminToken');
        if (token) {
            return !isTokenExpired(token);
        }
        return false;
    });

    const login = (token: string) => {
        localStorage.setItem('adminToken', token);
        setIsAuthenticated(!isTokenExpired(token));
    };

    const logout = () => {
        localStorage.removeItem('adminToken');
        setIsAuthenticated(false);
    };

    useEffect(() => {
        const token = localStorage.getItem('adminToken');
        if (token && isTokenExpired(token)) {
            logout();
        }
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};