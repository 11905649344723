import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import axiosInstance from '../utils/axiosInstanse';

interface Schedule {
    id: number;
    employee_id: number;
    employee_name: string;
    start_time: string;
    end_time: string;
    start_date: string;
    end_date: string;
}

interface Employee {
    id: number;
    name: string;
}

const localizer = momentLocalizer(moment);

const Schedules: React.FC = () => {
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [newSchedule, setNewSchedule] = useState<Partial<Schedule>>({
        employee_id: 0,
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
    });
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchSchedules();
        fetchEmployees();
    }, []);

    const fetchSchedules = async () => {
        try {
            const response = await axiosInstance.get('/schedules');
            setSchedules(response.data);
        } catch (err) {
            console.error('Error fetching schedules:', err);
            setError('Failed to fetch schedules.');
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axiosInstance.get('/employees');
            setEmployees(response.data);
        } catch (err) {
            console.error('Error fetching employees:', err);
            setError('Failed to fetch employees.');
        }
    };

    const handleAddSchedule = async (e: React.FormEvent) => {
        e.preventDefault();
    
        const { employee_id, start_date, end_date, start_time, end_time } = newSchedule;
        if (!employee_id || !start_date || !start_time || !end_time) {
            alert('All fields except End Date are required.');
            return;
        }
    
        try {
            const response = await axiosInstance.post('/schedules', {
                employee_id,
                start_date,
                end_date: end_date || start_date,
                start_time,
                end_time,
            });
    
            setSchedules((prev) => [...prev, ...response.data]); // Append all created schedules
            setNewSchedule({
                employee_id: 0,
                start_date: '',
                end_date: '',
                start_time: '',
                end_time: '',
            });
            alert('Schedules added successfully!');
        } catch (err) {
            console.error('Error adding schedules:', err);
            alert('Failed to add schedules.');
        }
    };

    const handleDeleteSchedule = async (id: number) => {
        if (!window.confirm('Are you sure you want to delete this schedule?')) return;

        try {
            await axiosInstance.delete(`/schedules/${id}`);
            setSchedules((prev) => prev.filter((schedule) => schedule.id !== id));
            alert('Schedule deleted successfully!');
        } catch (err) {
            console.error('Error deleting schedule:', err);
            alert('Failed to delete schedule.');
        }
    };

    const events = schedules.map((schedule) => {
        const start = moment(schedule.start_date).set({
            hour: parseInt(schedule.start_time.split(':')[0], 10),
            minute: parseInt(schedule.start_time.split(':')[1], 10),
        }).toDate();

        const end = moment(schedule.end_date || schedule.start_date).set({
            hour: parseInt(schedule.end_time.split(':')[0], 10),
            minute: parseInt(schedule.end_time.split(':')[1], 10),
        }).toDate();

        return {
            id: schedule.id,
            title: `${schedule.employee_name}: ${schedule.start_time} - ${schedule.end_time}`,
            start,
            end,
        };
    });

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Schedules</h1>

            {/* Add Schedule Form */}
            <div className="mb-6">
                <h2 className="text-lg font-semibold mb-2">Add Schedule</h2>
                <form onSubmit={handleAddSchedule} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium">Employee</label>
                        <select
                            value={newSchedule.employee_id || ''}
                            onChange={(e) => setNewSchedule({ ...newSchedule, employee_id: Number(e.target.value) })}
                            className="w-full border rounded px-3 py-2"
                            required
                        >
                            <option value="">Select Employee</option>
                            {employees.map((employee) => (
                                <option key={employee.id} value={employee.id}>
                                    {employee.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Start Date</label>
                        <input
                            type="date"
                            value={newSchedule.start_date || ''}
                            onChange={(e) => setNewSchedule({ ...newSchedule, start_date: e.target.value })}
                            className="w-full border rounded px-3 py-2"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">End Date (Optional)</label>
                        <input
                            type="date"
                            value={newSchedule.end_date || ''}
                            onChange={(e) => setNewSchedule({ ...newSchedule, end_date: e.target.value })}
                            className="w-full border rounded px-3 py-2"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">Start Time</label>
                        <input
                            type="time"
                            value={newSchedule.start_time || ''}
                            onChange={(e) => setNewSchedule({ ...newSchedule, start_time: e.target.value })}
                            className="w-full border rounded px-3 py-2"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">End Time</label>
                        <input
                            type="time"
                            value={newSchedule.end_time || ''}
                            onChange={(e) => setNewSchedule({ ...newSchedule, end_time: e.target.value })}
                            className="w-full border rounded px-3 py-2"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Add Schedule
                    </button>
                </form>
            </div>

            {/* Calendar View */}
            <div style={{ height: '500px' }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    titleAccessor="title"
                    style={{ height: 500 }}
                    views={['month', 'week', 'day']}
                    defaultView="month"
                    components={{
                        event: ({ event }) => (
                            <div>
                                {event.title}
                                <button
                                    onClick={() => handleDeleteSchedule(event.id)}
                                    className="text-red-500 hover:underline ml-2"
                                >
                                    Delete
                                </button>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default Schedules;