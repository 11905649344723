import React from 'react';
import { useNavigate } from 'react-router-dom';

const Main = () => {

    const navigate = useNavigate()

    return (
        <div className=' h-screen flex justify-center flex-wrap content-start'>
            <h1 className=' text-4xl pt-10 text-center w-full'>Admin panel</h1>

            <div className="pages w-3/6 flex justify-between mt-10">
                <div className="employees">
                    <button onClick={() => navigate('/employees')} className='bg-[#312E97] rounded-lg w-[170px] h-[30px]'>
                        <h2 className='text-white text-xl'>Employees</h2>
                    </button>
                </div>
                <div className="shifts">
                    <button onClick={() => navigate('/shifts')} className='bg-[#312E97] rounded-lg w-[170px] h-[30px]'>
                        <h2 className='text-white text-xl'>Shifts</h2>
                    </button>
                </div>                
                <div className="payments">
                    <button onClick={()=> navigate('/payments')} className='bg-[#312E97] rounded-lg w-[170px] h-[30px]'>
                        <h2 className='text-white text-xl'>Payments</h2>
                    </button>
                </div>
                <div className="schedules">
                    <button onClick={()=> navigate('/schedules')} className='bg-[#312E97] rounded-lg w-[170px] h-[30px]'>
                        <h2 className='text-white text-xl'>Schedules</h2>
                    </button>
                </div>
            </div>

        </div>
    );
};

export default Main;