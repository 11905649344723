import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Employee, Payment, Stats } from '../interfaces';
import axiosInstance from '../utils/axiosInstanse';

const Payments: React.FC = () => {
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<number | null>(null);
    const [stats, setStats] = useState<Stats | null>(null);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [newPayment, setNewPayment] = useState<Partial<Payment>>({});
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axiosInstance.get<Employee[]>(`/employees`);
                setEmployees(response.data);
            } catch (err) {
                setError('Failed to fetch employees.');
            }
        };

        fetchEmployees();
    }, []);

    useEffect(() => {
        if (selectedEmployee) {
            fetchEmployeeStats(selectedEmployee);
        }
    }, [selectedEmployee]);

    const fetchEmployeeStats = async (employeeId: number) => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/employee/${employeeId}/stats`);
            setStats(response.data.stats);
            setPayments(response.data.payments);
        } catch (err) {
            setError('Failed to fetch employee stats and payments.');
        } finally {
            setLoading(false);
        }
    };

    const handleCreatePayment = async () => {
        try {
            const response = await axiosInstance.post(`/payments`, {
                employee_id: selectedEmployee,
                ...newPayment,
            });
            setPayments([response.data, ...payments]);
            setStats((prev) =>
                prev ? { ...prev, total_paid: prev.total_paid + response.data.amount, amount_owed: prev.amount_owed - response.data.amount } : null
            );
            setModalVisible(false);
        } catch (err) {
            alert('Failed to create payment.');
        }
    };


    const convertToHHMM = (decimalHours: number): string => {
        const hours = Math.floor(decimalHours); // Get the integer part (hours)
        const minutes = Math.round((decimalHours - hours) * 60); // Convert the fractional part to minutes
        return `${hours}:${minutes.toString().padStart(2, '0')}`; // Format as hh:mm
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Payments</h1>

            {/* Employee Selector */}
            <div className="mb-4">
                <label htmlFor="employee-selector" className="block mb-2 text-sm font-medium">
                    Select Employee
                </label>
                <select
                    id="employee-selector"
                    className="border border-gray-300 rounded w-full px-3 py-2"
                    onChange={(e) => setSelectedEmployee(Number(e.target.value))}
                >
                    <option value="">Select an Employee</option>
                    {employees.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                            {employee.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Statistics */}
            {stats && (
                <div className="mb-4">
                    <h2 className="text-lg font-semibold">Statistics</h2>
                    <p>Total Hours Worked: {convertToHHMM(stats.hours_worked)} hrs</p>
                    <p>Total Earned: ${Number(stats.total_earned).toFixed(2)}</p>
                    <p>Total Paid: ${Number(stats.total_paid).toFixed(2)}</p>
                    <p>Amount Owed: ${Number(stats.amount_owed).toFixed(2)}</p>
                </div>
            )}
            {/* Create Payment Button */}
            {selectedEmployee
            ?<div className="mb-4 flex justify-end">
            <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={() => setModalVisible(true)}
            >
                Create Payment
            </button>
            </div>
            :null}

            {/* Payment History */}
            <h2 className="text-lg font-semibold mb-2">Payment History</h2>
            <table className="min-w-full bg-white border border-gray-300 text-left">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b ">Date</th>
                        <th className="py-2 px-4 border-b">Amount</th>
                        <th className="py-2 px-4 border-b">Type</th>
                        <th className="py-2 px-4 border-b">Destination</th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map((payment) => (
                        <tr key={payment.id}>
                            <td className="py-2 px-4 border-b">{new Date(payment.date).toLocaleDateString()}</td>
                            <td className="py-2 px-4 border-b">${Number(payment.amount).toFixed(2)}</td>
                            <td className="py-2 px-4 border-b">{payment.type}</td>
                            <td className="py-2 px-4 border-b">{payment.destination}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Create Payment Modal */}
            {modalVisible && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-md shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Create Payment</h2>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium">Amount</label>
                            <input
                                type="number"
                                className="border border-gray-300 rounded w-full px-3 py-2"
                                onChange={(e) => setNewPayment({ ...newPayment, amount: Number(e.target.value) })}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium">Type</label>
                            <input
                                type="text"
                                className="border border-gray-300 rounded w-full px-3 py-2"
                                onChange={(e) => setNewPayment({ ...newPayment, type: e.target.value })}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium">Destination</label>
                            <input
                                type="text"
                                className="border border-gray-300 rounded w-full px-3 py-2"
                                onChange={(e) => setNewPayment({ ...newPayment, destination: e.target.value })}
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="mr-2 px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                                onClick={() => setModalVisible(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                onClick={handleCreatePayment}
                            >
                                Create Payment
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Payments;