import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Auth from './pages/Auth';
import Main from './pages/Main';
import Employees from './pages/Employees';
import Shifts from './pages/Shifts';
import Payments from './pages/Payments';
import Schedules from './pages/Schedules';
import Header from './components/Header';

const App: React.FC = () => {
    return (
        <Routes>
            <Route path="/login" element={<Auth />} />

            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <Main />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/main"
                element={
                    <ProtectedRoute>
                        <Main />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/employees"
                element={
                    <ProtectedRoute>
                        <Header/>
                        <Employees />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/shifts"
                element={
                    <ProtectedRoute>
                        <Header/>
                        <Shifts />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/payments"
                element={
                    <ProtectedRoute>
                        <Header/>
                        <Payments />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/schedules"
                element={
                    <ProtectedRoute>
                        <Header/>
                        <Schedules/>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

const Root: React.FC = () => (
    <AuthProvider>
        <Router>
            <App />
        </Router>
    </AuthProvider>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Root />);