import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Employee } from '../interfaces';
import Header from '../components/Header';
import axiosInstance from '../utils/axiosInstanse';

const Employees: React.FC = () => {
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axiosInstance.get<Employee[]>(`/employees`);
                setEmployees(response.data);
            } catch (err) {
                setError('Failed to fetch employees. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchEmployees();
    }, []);

    const openEditModal = (employee: Employee) => {
        setSelectedEmployee(employee);
        setEditModalVisible(true);
    };

    const closeEditModal = () => {
        setSelectedEmployee(null);
        setEditModalVisible(false);
    };

    const handleSave = async () => {
        if (selectedEmployee) {
            try {
                await axiosInstance.put(`/employees/${selectedEmployee.id}`, {
                    name: selectedEmployee.name,
                    position: selectedEmployee.position,
                    wage: selectedEmployee.wage,
                    status: selectedEmployee.status,
                });
                setEmployees((prev) =>
                    prev.map((emp) =>
                        emp.id === selectedEmployee.id ? selectedEmployee : emp
                    )
                );
                closeEditModal();
            } catch (err) {
                alert('Failed to update employee.');
            }
        }
    };

    const handleDelete = async (employee: Employee) => {
        try {
            await axiosInstance.delete(`/employees/${employee.id}`);
            setEmployees((prev) => prev.filter((emp) => emp.id !== employee.id));
        } catch (err) {
            alert('Failed to delete employee.');
        }
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Filter employees into two groups: 'pending' and others
    const pendingEmployees = employees.filter(emp => emp.position.toLowerCase() === 'pending');
    const activeEmployees = employees.filter(emp => emp.position.toLowerCase() !== 'pending');

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Employees</h1>

            {/* Active Employees Section */}
            <h2 className="text-xl font-bold mb-2">Active Employees</h2>
            <table className="min-w-full bg-white border border-gray-300 mb-6">
                <thead>
                    <tr className="bg-gray-100 text-left">
                        <th className="py-2 px-4 border-b">Name</th>
                        <th className="py-2 px-4 border-b">Position</th>
                        <th className="py-2 px-4 border-b">Wage</th>
                        <th className="py-2 px-4 border-b">Status</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {activeEmployees.map((employee) => (
                        <tr key={employee.id} className="hover:bg-gray-50">
                            <td className="py-2 px-4 border-b">{employee.name}</td>
                            <td className="py-2 px-4 border-b">{employee.position}</td>
                            <td className="py-2 px-4 border-b">{employee.wage || 'N/A'}</td>
                            <td className="py-2 px-4 border-b capitalize">{employee.status}</td>
                            <td className="py-2 px-4 border-b">
                                <button
                                    className="mr-2 text-blue-500 hover:underline"
                                    onClick={() => openEditModal(employee)}
                                >
                                    Edit
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pending Employees Section */}
            {pendingEmployees.length>0
            ?<div><h2 className="text-xl font-bold mb-2">Pending Employees</h2>
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-gray-100 text-left">
                        <th className="py-2 px-4 border-b">Name</th>
                        <th className="py-2 px-4 border-b">Position</th>
                        <th className="py-2 px-4 border-b">Wage</th>
                        <th className="py-2 px-4 border-b">Status</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingEmployees.map((employee) => (
                        <tr key={employee.id} className="hover:bg-gray-50">
                            <td className="py-2 px-4 border-b">{employee.name}</td>
                            <td className="py-2 px-4 border-b">{employee.position}</td>
                            <td className="py-2 px-4 border-b">{employee.wage || 'N/A'}</td>
                            <td className="py-2 px-4 border-b capitalize">{employee.status}</td>
                            <td className="py-2 px-4 border-b">
                                <button
                                    className="mr-2 text-blue-500 hover:underline"
                                    onClick={() => openEditModal(employee)}
                                >
                                    Approve
                                </button>
                                <button
                                    className="mr-2 text-red-500 hover:underline"
                                    onClick={() => handleDelete(employee)}
                                >
                                    Delete
                                </button>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            :null}

            {/* Edit Modal */}
            {editModalVisible && selectedEmployee && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-md shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Edit Employee</h2>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium">Position</label>
                            <input
                                type="text"
                                className="border border-gray-300 rounded w-full px-3 py-2"
                                value={selectedEmployee.position || ''}
                                onChange={(e) =>
                                    setSelectedEmployee((prev) =>
                                        prev
                                            ? { ...prev, position: e.target.value }
                                            : null
                                    )
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium">Wage</label>
                            <input
                                type="number"
                                className="border border-gray-300 rounded w-full px-3 py-2"
                                value={selectedEmployee.wage || ''}
                                onChange={(e) =>
                                    setSelectedEmployee((prev) =>
                                        prev
                                            ? { ...prev, wage: parseInt(e.target.value, 10) || null }
                                            : null
                                    )
                                }
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="mr-2 px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                                onClick={closeEditModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Employees;