import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Break, Employee, Shift } from '../interfaces';
import moment from 'moment';
import Header from '../components/Header';
import axiosInstance from '../utils/axiosInstanse';

const Shifts: React.FC = () => {
    const [shifts, setShifts] = useState<Shift[]>([]);
    const [employees, setEmployees] = useState<Employee[]>([]); // List of employees
    const [selectedEmployee, setSelectedEmployee] = useState<number | 'all'>('all'); // Selected employee ID or 'all'
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

    useEffect(() => {
        const fetchShiftsAndEmployees = async () => {
            try {
                const [shiftsResponse, employeesResponse] = await Promise.all([
                    axiosInstance.get<Shift[]>(`/shifts`),
                    axiosInstance.get<Employee[]>(`/employees`),
                ]);
                setShifts(shiftsResponse.data);
                setEmployees(employeesResponse.data);
            } catch (err) {
                setError('Failed to fetch shifts or employees. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchShiftsAndEmployees();
    }, []);


    const fetchBreaks = async (shiftId: number) => {
        try {
            const response = await axiosInstance.get<Break[]>(
                `/shifts/${shiftId}/breaks`
            );
    
            setShifts((prev) =>
                prev.map((shift) =>
                    shift.id === shiftId ? { ...shift, breaks: response.data } : shift
                )
            );
        } catch (err) {
            alert('Failed to fetch breaks for the shift.');
        }
    };

    const filteredShifts = selectedEmployee === 'all'
    ? shifts
    : shifts.filter(shift => shift.employee_id === selectedEmployee);

const handleEmployeeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === 'all' ? 'all' : parseInt(e.target.value, 10);
    setSelectedEmployee(value);
};

const calculateDuration = (start: string, end: string | null): string => {
    if (!end) return 'Ongoing';
    const duration = moment.duration(moment(end).diff(moment(start)));
    return `${Math.floor(duration.asHours())}:${(duration.minutes() % 60).toString().padStart(2, '0')}`;
};

const calculateBreakDuration = (breaks: Break[] | undefined): string => {
    if (!breaks || breaks.length === 0) return '0:00';
    const totalDuration = breaks.reduce((total, b) => {
        if (!b.end_time) return total; // Skip ongoing breaks
        return total + moment(b.end_time).diff(moment(b.start_time));
    }, 0);
    const duration = moment.duration(totalDuration);
    return `${Math.floor(duration.asHours())}:${(duration.minutes() % 60).toString().padStart(2, '0')}`;
};

if (loading) {
    return <div>Loading...</div>;
}

if (error) {
    return <div>{error}</div>;
}


    const openEditModal = (shift: Shift) => {
        setSelectedShift(shift);
        setEditModalVisible(true);
    };

    const closeEditModal = () => {
        setSelectedShift(null);
        setEditModalVisible(false);
    };

    const handleSave = async () => {
        if (selectedShift) {
            try {
                await axiosInstance.put(`/shifts/${selectedShift.id}`, {
                    start_time: selectedShift.start_time,
                    end_time: selectedShift.end_time,
                    wage: selectedShift.wage,
                });
                setShifts((prev) =>
                    prev.map((shift) =>
                        shift.id === selectedShift.id ? selectedShift : shift
                    )
                );
                closeEditModal();
            } catch (err) {
                alert('Failed to update shift.');
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    async function handleDelete(id:number) {
        try {
            await axiosInstance.delete(`/shifts/${id}`);
            setShifts((prev) =>
                prev.filter((shift) =>
                    shift.id !== id 
                )
            );
            closeEditModal();
        } catch (err) {
            alert('Failed to delete shift.');
        }
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Shifts</h1>
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-gray-100 text-left">
                        <th className="py-2 px-4 border-b">Employee Name</th>
                        <th className="py-2 px-4 border-b">Start Time</th>
                        <th className="py-2 px-4 border-b">End Time</th>
                        <th className='py-2 px-4 border-b'>Duration</th>
                        <th className="py-2 px-4 border-b">Wage</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                {shifts.map((shift) => (
                    <React.Fragment key={shift.id}>
                        <tr className="hover:bg-gray-50">
                            <td className="py-2 px-4 border-b">{shift.employee_name} (ID: {shift.employee_id})</td>
                            <td className="py-2 px-4 border-b">
                                {moment(shift.start_time).format('ddd, MMM Do YYYY, h:mm:ss a')}
                            </td>
                            <td className="py-2 px-4 border-b">
                                {shift.end_time
                                    ? moment(shift.end_time).format('ddd, MMM Do YYYY, h:mm:ss a')
                                    : ' '}
                            </td>
                            <td className="py-2 px-4 border-b">
                                {calculateDuration(shift.start_time,shift.end_time)}
                            </td>
                            <td className="py-2 px-4 border-b">{shift.wage}</td>
                            <td className="py-2 px-4 border-b">
                                <button
                                    className="mr-2 text-blue-500 hover:underline"
                                    onClick={() => fetchBreaks(shift.id)}
                                >
                                    {shift.breaks ? 'Breaks' : 'Breaks'}
                                </button>
                                <button
                                    className="mr-2 text-red-500 hover:underline"
                                    onClick={() => handleDelete(shift.id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                        {shift.breaks && (
                            <tr>
                                <td colSpan={5} className="py-2 px-4 border-b bg-gray-100">
                                    <h3 className="text-lg font-semibold">Breaks:{`(${calculateBreakDuration(shift.breaks)})`}</h3>
                                    <ul className="list-disc ml-6">
                                        {shift.breaks.length>0
                                        ?shift.breaks.map((breakItem:Break) => (
                                            <li key={breakItem.id}>
                                                {moment(breakItem.start_time).format(
                                                    'ddd, MMM Do YYYY, h:mm:ss a'
                                                )}{' '}
                                                -{' '}
                                                {breakItem.end_time
                                                    ? moment(breakItem.end_time).format(
                                                        'ddd, MMM Do YYYY, h:mm:ss a'
                                                    )
                                                    : 'Ongoing'}
                                                
                                            </li>
                                        ))
                                    :"no breaks"}
                                    </ul>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
                
            </table>

            {/* {editModalVisible && selectedShift && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-md shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Edit Shift</h2>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium">Start Time</label>
                            <input
                                type="datetime-local"
                                className="border border-gray-300 rounded w-full px-3 py-2"
                                value={selectedShift.start_time}
                                onChange={(e) =>
                                    setSelectedShift((prev) =>
                                        prev
                                            ? { ...prev, start_time: e.target.value }
                                            : null
                                    )
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium">End Time</label>
                            <input
                                type="datetime-local"
                                className="border border-gray-300 rounded w-full px-3 py-2"
                                value={selectedShift.end_time}
                                onChange={(e) =>
                                    setSelectedShift((prev) =>
                                        prev
                                            ? { ...prev, end_time: e.target.value }
                                            : null
                                    )
                                }
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-medium">Wage</label>
                            <input
                                type="number"
                                className="border border-gray-300 rounded w-full px-3 py-2"
                                value={selectedShift.wage}
                                onChange={(e) =>
                                    setSelectedShift((prev) =>
                                        prev
                                            ? { ...prev, wage: parseInt(e.target.value, 10) || 0 }
                                            : null
                                    )
                                }
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="mr-2 px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                                onClick={closeEditModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default Shifts;